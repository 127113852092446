











import Vue from "vue";
import { Component } from "vue-property-decorator";

import SeminarList from "../components/SeminarList.vue";
import CreateSeminar from "../components/admin/CreateSeminar.vue";

@Component({ components: { SeminarList, CreateSeminar } })
export default class SeminarsAdmin extends Vue {}
